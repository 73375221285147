/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import type { MangoQuery } from 'rxdb';
import { convertQueryToRxDBQuery } from 'shared/filters-compiler/convertQueryToRxDBQuery';
import database from 'shared/foreground/database';
import { globalState, updatePropertyInClientState, useIsStaffProfile } from 'shared/foreground/models';
import type { AnyDocument } from 'shared/types';

import StandardPage from './StandardPage';

const DefaultMangoQuery: MangoQuery<AnyDocument> = {
  selector: {
    title: {
      $regex: 'bitcoin',
    },
  },
};

const DefaultFilterQuery = 'rssSource:"01fxcp88nrz0tdg28t1acw7sxx"';

export default function DatabaseExplorer() {
  const databaseExplorerState = globalState((state) => state.client.databaseExplorerState);

  const [mangoQuery, setMangoQuery] = useState(
    databaseExplorerState?.currentMangoQuery ?? DefaultMangoQuery,
  );
  const [mangoQueryString, setMangoQueryString] = useState(JSON.stringify(mangoQuery, null, 2));
  const [filterQuery, setFilterQuery] = useState(
    databaseExplorerState?.currentFilterQuery ?? DefaultFilterQuery,
  );
  const isStaff = useIsStaffProfile();

  useEffect(() => {
    updatePropertyInClientState(
      'databaseExplorerState',
      {
        currentMangoQuery: mangoQuery,
        currentFilterQuery: filterQuery,
      },
      {
        eventName: 'database-explorer-state-updated',
        userInteraction: null,
        isUndoable: false,
      },
    );
  }, [mangoQuery, filterQuery]);

  const runRxDBQuery = async () => {
    let mangoQuery: MangoQuery<AnyDocument>;
    try {
      mangoQuery = JSON.parse(mangoQueryString);
    } catch (e) {
      if (e instanceof Error) {
        // eslint-disable-next-line no-alert
        window.alert(`Error during JSON parse: \n${e.message}`);
      }
      return;
    }
    setMangoQuery(mangoQuery);
    console.log('=> Mango query', mangoQuery);
    console.time('=> RxDB Query time');
    const docs = await database.collections.documents.find(mangoQuery);
    console.timeEnd('=> RxDB Query time');
    console.log('=> Result documents', docs);
  };

  const runFilterQuery = async () => {
    const { mangoQuery } = convertQueryToRxDBQuery({ query: filterQuery });

    if (!mangoQuery) {
      console.log('=> invalid filter query');
      return;
    }

    console.time('=> Filter Query');
    console.log('=> mangoQuery', mangoQuery);
    const docs = await database.collections.documents.find(mangoQuery);
    console.timeEnd('=> Filter Query');
    console.log('=> docs', docs);
  };

  if (!isStaff) {
    return null;
  }

  return (
    <StandardPage>
      <div style={{ width: '100%', overflowY: 'auto' }}>
        <pre>Open the console to inspect results and set breakpoints.</pre>
        <div>
          <textarea
            value={mangoQueryString}
            style={{ width: '100%', height: '350px' }}
            name="template"
            aria-label="template"
            autoComplete="false"
            autoFocus
            onChange={(evt) => setMangoQueryString(evt.target.value)}
          />
          <div>
            <button style={{ all: 'revert', marginTop: '20px' }} onClick={runRxDBQuery} type="button">
              Run RxDB query
            </button>
            <button
              style={{ all: 'revert', marginTop: '20px' }}
              onClick={() =>
                setMangoQuery(databaseExplorerState?.currentMangoQuery ?? DefaultMangoQuery)
              }
              type="button"
            >
              Reset RxDB query
            </button>
          </div>
        </div>
        <hr />
        <div>
          <textarea
            value={filterQuery}
            style={{ width: '100%', height: '350px' }}
            name="template"
            aria-label="template"
            autoComplete="false"
            autoFocus
            onChange={(evt) => setFilterQuery(evt.target.value)}
          />
          <button style={{ all: 'revert', marginTop: '20px' }} onClick={runFilterQuery} type="button">
            Run filter query
          </button>
          <button
            style={{ all: 'revert', marginTop: '20px' }}
            onClick={() =>
              setFilterQuery(databaseExplorerState?.currentFilterQuery ?? DefaultFilterQuery)
            }
            type="button"
          >
            Reset filter query
          </button>
        </div>
      </div>
    </StandardPage>
  );
}
